<template>
    
  <div class="search helper-scss">
    <div class="container">
      <div class="search-title text-center">全站搜索</div>
      <div class="flex top flex-justify-between">
        <div class="flex-item">
          <div class="search-input flex">
            <div class="flex-item flex-item-center keyword">关键词:</div>
            <input
              class="flex-item input flex-item-center"
              type="text"
              v-model="keyword"
            />
          </div>
        </div>
        <div class="flex-item">
          <div class="search-btn text-center cursor-pointer" @click="search">
            搜索
          </div>
        </div>
      </div>
      <div class="hot-search">
        <span class="hot">热门搜索:</span>
        <span
          class="hot-tag cursor-pointer"
          v-for="(hotword, index) in hotwords"
          :key="index"
          @click="searchHot(hotword.search_name)"
          >{{ hotword.search_name }}
          </span
        >
      </div>
      <div v-if="resultsNumActive" class="results-num">
        相关搜索结果{{ totalCount }}个
      </div>
      <div
        class="result flex"
        v-for="(result, index) in searchResults"
        :key="index"
      >
        <div class="flex-item">
          <img class="image" src="../assets/titleImg.png" />
        </div>
        <div class="flex-item flex-1">
          <div class="result-title flex flex-justify-between">
            <div class="flex flex-item flex-item-center">
              <div
                class="text flex-item flex-item-center cursor-pointer"
                @mousedown.stop="an"
                @mouseup.stop="song(result)"
              >
                {{ result.news_title }}
              </div>
            </div>
            <div class="time flex-item flex-item-center">
              {{ result.news_time }}
            </div>
          </div>
          <div
            class="result-content cursor-pointer"
            @mousedown.stop="an"
            @mouseup.stop="song(result)"
          >
            {{ result.news_scontent }}
          </div>
        </div>
      </div>
      <!-- <el-pagination
        v-if="this.totalCount !== 0"
        class="pagenation"
        background
        layout="prev, pager, next"
        :total=""
        :page-size=""
        prev-text="上一页"
        next-text="下一页"
        @current-change="currentChange"
      >
      </el-pagination> -->
      <Pagetion
        :total="totalCount"
        :pageSize="pageSize"
        @ClickChange="currentChange"
      ></Pagetion>
    </div>
  </div>
</template>

<script>
import { fetch } from "../api/https";
import moment from "moment";
import Pagetion from "../components/pagetion/pagetion.vue";
export default {
  components: { Pagetion },
  data() {
    return {
      hotwords: [],
      searchResults: [],
      totalCount: 0,
      page: 1,
      keyword: "",
      resultsNumActive: false,
      pageSize: 8,
      timeLength: 0,
      oldtime: 0,
    };
  },
  async mounted() {
    this.getHotwords();
    document.title = "全站搜索";
  },
  methods: {
    an() {
      //鼠标按下
      this.timeLength = new Date().getTime();
    },
    song(result) {
      //鼠标松开 判断鼠标点击的时间
      this.oldtime = new Date().getTime();
      if (this.oldtime - this.timeLength < 130) {
        this.$router.push(result.url);
      }
    },
    async search() {
      this.resultsNumActive = true;
      this.searchResults = [];
      let searchResults = await fetch("/search", {
        keywords: this.keyword.trim(),
        page: this.page,
        pagesize: this.pageSize,
      });
      if (searchResults.data.list !== undefined) {
        searchResults.data.list.forEach(
          (ite) =>
            (ite.news_time = moment(1000 * parseInt(ite.news_time)).format(
              "YYYY-MM-DD H:m:S"
            ))
        );
        this.searchResults = searchResults.data.list;
      }
      this.totalCount = searchResults.data.total_count || 0;
    },
    async getHotwords() {
      let hotwords = await fetch("/search_hot");
      this.hotwords = hotwords.data;
      console.log(this.hotwords);
    },
    currentChange(val) {
      this.page = val;
      this.search();
    },
    async searchHot(val) {
      this.keyword = val;
      this.search();
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  background-color: #f9f9f9;
  padding: 80px 0;
  min-height: calc(100vh - 97px);
  box-sizing: border-box;
  .container {
    max-width: 1400px;
    margin: 0 auto;
    .search-title {
      background: url("../assets/search/line.png") center center no-repeat;
      font-size: 24px;
      color: #333333;
      margin-bottom: 45px;
    }
    .top {
      width: 763px;
      margin: 0 auto 28px;
      .search-input {
        background: url("../assets/search/search-input.png") center center
          no-repeat;
        background-size: 584px 42px;
        width: 584px;
        height: 42px;
        .keyword {
          padding: 0 30px;
          font-size: 14px;
          color: #666666;
        }
        .input {
          background-color: #f9f9f9;
          border: none;
          outline: none;
          height: 30px;
          font-size: 16px;
          color: #999999;
          width: 400px;
        }
      }
      .search-btn {
        background: url("../assets/search/search-btn.png") center center
          no-repeat;
        width: 125.9px;
        height: 42px;
        color: #fff;
        font-size: 16px;
        line-height: 42px;
      }
    }
    .hot-search {
      font-size: 20px;
      width: 763px;
      margin: 0 auto 56px;
      .hot {
        color: #c19c66;
        padding-right: 10px;
      }
      .hot-tag {
        color: #666666;
        padding-right: 30px;
      }
    }
    .results-num {
      margin: 0 auto 25px;
      font-size: 20px;
      color: #666666;
    }
    .result {
      padding: 28px 0;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 60px;
      .image {
        width: 26px;
        height: 21px;
        margin-right: 25px;
        margin-top: 3px;
      }
      .result-title {
        margin-bottom: 20px;
        .text {
          font-size: 20px;
          color: #333333;
        }
        .time {
          color: #b5b5b5;
          font-size: 18px;
        }
      }
      .result-content {
        color: #666666;
        font-size: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 1200px;
      }
    }
    .pagenation {
      text-align: center;
    }
  }
}
@media screen and (max-width: 1440px) {
  .search-title {
    width: 1266px;
    margin: 0 auto;
  }
  .results-num {
    margin: 0 auto;
    width: 1266px;
  }
  .result {
    margin: 0 auto;
    width: 1266px;
  }
}
</style>

<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #c19c66;
  color: #fff;
  width: 60px;
  height: 40px;
}
.el-pagination.is-background .el-pager li.active {
  color: #fff;
  cursor: default;
  width: 60px;
  height: 40px;
}
.el-pagination.is-background .el-pager li:hover {
  color: #c19c66;
  width: 60px;
  height: 40px;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #c19c66;
  width: 60px;
  height: 40px;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #c19c66;
  color: #fff;
  width: 60px;
  height: 40px;
}
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  height: 40px;
  line-height: 40px;
  width: 60px;
  height: 40px;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  border: 1px solid #e6e6e6;
  min-width: 74px;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
}
</style>

